body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Inter_24pt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Inter_24pt-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Inter_24pt-Bold.ttf") format("truetype");
}

/* report */


.containercard {
  padding-left: 0px;
  padding-right: 0px;
}

.cardContentReport {
  margin: 0px;
  padding: 0px;
}

.cardContentReport:last-child {
  padding-bottom: 0px;
}